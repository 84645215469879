import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { momentObj } from 'react-moment-proptypes';
import { availabilityTypeAvailable } from './constants';
import WorkerAvailability from './models/WorkerAvailability';

export default class LaborAvailabilitySums extends React.PureComponent {
  static propTypes = {
    dates: PropTypes.arrayOf(momentObj).isRequired,
    i18n: PropTypes.shape({ t: PropTypes.func.isRequired }).isRequired,
    workerAvailability: PropTypes.arrayOf(WorkerAvailability),
  };

  static defaultProps = {
    workerAvailability: [],
  };

  render() {
    return (
      <li className="labor-availability-calendar__sums">
        <header className="labor-availability-calendar__sums-label">Total:</header>
        <ol className="labor-availability-calendar__sums-items">
          {this.props.dates.map(date => {
            let totalPerDay = 0;
            this.props.workerAvailability.forEach(project => {
              const qteWorkers = project.workers.filter(({ availabilities }) => {
                let availabilityStatus;
                if (availabilities.length !== 0) {
                  const sameDayAvailability = availabilities.find(availability => {
                    return Moment(date).isSame(availability.date, 'day');
                  });
                  if (sameDayAvailability) {
                    availabilityStatus = sameDayAvailability.availability;
                  } else {
                    availabilityStatus = project.defaultAvailability;
                  }
                } else {
                  availabilityStatus = project.defaultAvailability;
                }
                return availabilityStatus === availabilityTypeAvailable;
              }).length;
              totalPerDay += qteWorkers;
            });
            return (
              <li className="labor-availability-calendar__sums-item" key={date}>
                {this.props.i18n.t('workers', { quantity: totalPerDay })}
              </li>
            );
          })}
        </ol>
      </li>
    );
  }
}
