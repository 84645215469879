import Moment from 'moment';
import { daysInWeek, longTermProjectNumberOfDaysDelimiter } from '../constants';
import { getMomentExcludingMidnight } from './momentHelpers';

const minMax = (num, min, max) => Math.min(Math.max(num, min), max);

export const createTimelineLanes = (slotsPerLane, timelines) => {
  const createReferencedEntitySlots = (key, entity, slotCount) =>
    Array(slotCount).fill({ key, entity, size: (slotCount * 100) / slotsPerLane, isGap: false });

  const createEmptyLane = () =>
    Array.from(Array(slotsPerLane), (X, index) => ({
      key: `gap-${index}`,
      entity: null,
      size: (1 / slotsPerLane) * 100,
      isGap: true,
    }));

  const timelineReducer = (lanes, [entity, slotRange], index) => {
    const { startSlot, endSlot } = slotRange;
    const key = entity.key || entity.id || index;
    const vacantLaneIndex = lanes.findIndex(lane => lane.slice(startSlot, endSlot).every(slot => !slot.entity));
    const laneIndex = vacantLaneIndex < 0 ? lanes.push(createEmptyLane()) - 1 : vacantLaneIndex;
    const slotCount = minMax(endSlot - startSlot, 1, slotsPerLane);
    lanes[laneIndex].splice(startSlot, slotCount, ...createReferencedEntitySlots(key, entity, slotCount));
    return lanes;
  };

  const mergeEntries = lane =>
    Array.from(new Map(lane.map(slot => [slot])).keys()).reduce((slots, slot, index) => {
      if (!slot.isGap || !index) return [...slots, slot];

      const prevSlot = slots.pop();
      if (!prevSlot.isGap) return [...slots, prevSlot, slot];
      prevSlot.size += slot.size;
      return [...slots, prevSlot];
    }, []);

  const removeTrailingGaps = lane => (lane[lane.length - 1].isGap ? lane.slice(0, -1) : lane);

  return timelines
    .reduce(timelineReducer, [createEmptyLane()])
    .map(mergeEntries)
    .map(removeTrailingGaps);
};

export const getProjectsByWeek = (firstDate, lastDate, projects) => {
  const getWeeksInRange = (startDate, endDate) => {
    const startWeek = Moment(startDate).week();
    const endWeek = getMomentExcludingMidnight(Moment(endDate)).week();

    if (endWeek >= startWeek) {
      return Array(endWeek - startWeek + 1)
        .fill(startWeek)
        .map((n, index) => n + index);
    }
    return Array(endWeek + Moment().weeksInYear() - startWeek + 1)
      .fill(startWeek)
      .map((n, index) => (n + index > Moment().weeksInYear() ? n + index - Moment().weeksInYear() : n + index));
  };

  return projects.reduce((projectMap, project) => {
    getWeeksInRange(project.startDate, project.endDate).forEach(week => {
      if (projectMap.has(week)) projectMap.get(week).push(project);
    });

    return projectMap;
  }, new Map(getWeeksInRange(firstDate, lastDate).map(week => [week, []])));
};

export const getProjectsForWeek = (projectStartDate, projects) => {
  if (!projects) return [];

  const shortTermProjects = projects.filter(
    ({ startDate, endDate }) => Moment(endDate).diff(startDate, 'days') <= longTermProjectNumberOfDaysDelimiter
  );

  const startOfWeek = Moment(projectStartDate).startOf('week');
  const dateIterator = Moment(startOfWeek).subtract(1, 'days');
  const dates = Array.from(Array(daysInWeek), () => Moment(dateIterator.add(1, 'day')));
  const [firstDate] = dates;
  const lastDate = dates[daysInWeek - 1];
  const weekNumber = firstDate.week();
  const projectsMap = getProjectsByWeek(firstDate, lastDate, shortTermProjects);

  return projectsMap.get(weekNumber);
};
