import Icon from '@vooban/icon';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import WorkerAvailability from './models/WorkerAvailability';
import ManagerButton from './ManagerButton';
import SiteConfiguration from './models/SiteConfiguration';
import { availabilityTypeNotAvailable } from './constants';
import GroupConfiguration from './models/GroupConfiguration';

const LaborAvailabilityDayManager = ({
  isRefused,
  availability,
  isAvailable,
  isNotAvailable,
  isDesktopOrLarger,
  i18n,
  displayAction,
  isEditable,
  onAvailabilityClick,
  onCalledClick,
  onInfractionClick,
  isUnavailableByDefault,
  workerPhoneNumber,
  siteConfiguration,
  groupConfiguration,
}) => {
  const refusedAndIsDesktopOrLarger = isRefused && isDesktopOrLarger;

  const shouldDisplayEditButton = () => {
    if (siteConfiguration && siteConfiguration.availabilityTypes && siteConfiguration.availabilityTypes.length) {
      return (
        groupConfiguration.availabilityTypeConfigurations.length ||
        siteConfiguration.availabilityTypes.some(
          availabilityType => availabilityType.availability === availabilityTypeNotAvailable
        )
      );
    }
    return false;
  };

  const availabilityTypeName =
    i18n.language === 'en' && availability.availabilityTypeNameEn
      ? availability.availabilityTypeNameEn
      : availability.availabilityTypeNameFr;

  const availabilityValueContent = availability.isAvailabilityTypeSpecified
    ? availabilityTypeName
    : i18n.t(`availabilityTypes.${availability.availability}`);
  const renderContent = content => {
    let tooltipContent = i18n.t('managerActions.workerAvailable');
    if (isAvailable) tooltipContent = i18n.t('managerActions.workerUnavailable');
    if (isUnavailableByDefault) tooltipContent = i18n.t('managerActions.workerNotAvailableByDefault');
    if (!isEditable) tooltipContent = i18n.t('managerActions.workerNotEditable');
    if (shouldDisplayEditButton()) tooltipContent = i18n.t('managerActions.changeAvailability');
    let editButton;
    if (shouldDisplayEditButton()) {
      editButton = 'edit';
    } else {
      editButton = isNotAvailable ? 'checkmark' : 'cross';
    }

    if (displayAction) {
      return (
        <>
          <ManagerButton
            iconId={editButton}
            className={classNames({
              '-available': (!isAvailable && !shouldDisplayEditButton()) || (isAvailable && shouldDisplayEditButton()),
              '-non-available':
                (isAvailable && !shouldDisplayEditButton()) || (!isAvailable && shouldDisplayEditButton()),
              '-disabled': !isEditable || isUnavailableByDefault,
            })}
            disabled={!isEditable || isUnavailableByDefault}
            tooltipContent={tooltipContent}
            onClick={onAvailabilityClick}
          />
          <ManagerButton
            iconId="call-end"
            className="-called"
            tooltipContent={
              <div style={{ textAlign: 'center' }}>
                <p>{i18n.t('managerActions.workerCalled')}</p>
                {workerPhoneNumber && <p>{workerPhoneNumber}</p>}
              </div>
            }
            onClick={onCalledClick}
          />
          <ManagerButton
            iconId="local-police"
            className="-infraction"
            tooltipContent={i18n.t('managerActions.workerInfraction')}
            onClick={onInfractionClick}
          />
        </>
      );
    }

    return content;
  };

  let content = availabilityValueContent;

  if (refusedAndIsDesktopOrLarger) {
    content = (
      <>
        {availabilityValueContent}
        <Icon id="warn" className="-warn" />
      </>
    );
  }

  return renderContent(content);
};

LaborAvailabilityDayManager.propTypes = {
  isRefused: PropTypes.bool.isRequired,
  availability: WorkerAvailability.isRequired,
  isDesktopOrLarger: PropTypes.bool.isRequired,
  i18n: PropTypes.shape({ t: PropTypes.func, language: PropTypes.string }).isRequired,
  displayAction: PropTypes.bool,
  isEditable: PropTypes.bool.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  isNotAvailable: PropTypes.bool.isRequired,
  onAvailabilityClick: PropTypes.func.isRequired,
  onCalledClick: PropTypes.func.isRequired,
  onInfractionClick: PropTypes.func.isRequired,
  isUnavailableByDefault: PropTypes.bool.isRequired,
  workerPhoneNumber: PropTypes.string,
  siteConfiguration: PropTypes.shape(SiteConfiguration).isRequired,
  groupConfiguration: PropTypes.shape(GroupConfiguration).isRequired,
};

LaborAvailabilityDayManager.defaultProps = {
  displayAction: null,
  workerPhoneNumber: undefined,
};

export default LaborAvailabilityDayManager;
