import PropTypes from 'prop-types';
import WorkerInfraction from './WorkerInfraction';
import WorkerCall from './WorkerCall';
import AvailabilityTypeConfigurations from './AvailabilityTypeConfigurations';

const WorkerAvailability = PropTypes.shape({
  availability: PropTypes.string,
  availabilityReason: PropTypes.string,
  availabilityTypeDescriptionEn: PropTypes.string,
  availabilityTypeDescriptionFr: PropTypes.string,
  availabilityTypeNameEn: PropTypes.string,
  availabilityTypeNameFr: PropTypes.string,
  date: PropTypes.string,
  id: PropTypes.number,
  isAvailabilityTypeSpecified: PropTypes.bool,
  isProposalDayOff: PropTypes.bool,
  status: PropTypes.string,
  updatedOn: PropTypes.string,
  workerCall: WorkerCall,
  workerId: PropTypes.number,
  workerInfraction: WorkerInfraction,
  availabilityTypeConfigurations: PropTypes.arrayOf(PropTypes.shape(AvailabilityTypeConfigurations)),
  generalRequirementFr: PropTypes.string,
  generalRequirementEn: PropTypes.string,
});

export default WorkerAvailability;
