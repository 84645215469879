import PropTypes from 'prop-types';

const WorkerInfraction = PropTypes.shape({
  id: PropTypes.number.isRequired,
  infraction: PropTypes.string,
  reportedById: PropTypes.string,
  reportedByEmail: PropTypes.string,
  reportedByName: PropTypes.string,
  reportedOn: PropTypes.string,
  updatedOn: PropTypes.string,
});

export default WorkerInfraction;
