import PropTypes from 'prop-types';
import { availabilityTypes, availabilityUnitTypes } from '../constants';
import Worker from './Worker';
import AvailabilityTypeConfigurations from './AvailabilityTypeConfigurations';
import ProposalDaysOff from './ProposalDaysOff';

const GroupConfiguration = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  managerDeadline: PropTypes.string.isRequired,
  maximumNonAvailabilityDays: PropTypes.number.isRequired,
  maximumNonAvailabilityUnit: PropTypes.oneOf(availabilityUnitTypes).isRequired,
  defaultAvailability: PropTypes.oneOf(availabilityTypes).isRequired,
  workers: PropTypes.arrayOf(Worker).isRequired,
  workerDeadline: PropTypes.string.isRequired,
  isOccasional: PropTypes.bool.isRequired,
  unavailabilityDescriptionRequired: PropTypes.bool.isRequired,
  availabilityTypeConfigurations: PropTypes.arrayOf(PropTypes.shape(AvailabilityTypeConfigurations)).isRequired,
  proposalDaysOff: PropTypes.arrayOf(ProposalDaysOff),
});

export default GroupConfiguration;
