import React from 'react';
import PropTypes from 'prop-types';
import Project from './models/Project';
import { formatDate, isoDateHourFormat } from './helpers/momentHelpers';

const getCargoItemKey = (id, index) => `${id}.ci.${index}`;

const LaborAvailabilityProjectTooltipContent = props => {
  const { i18n } = props;
  const { id, name, number, startDate, endDate, cargo, clients, remark = [] } = props.project || {};

  return (
    <div style={{ padding: 8 }}>
      <div className="grid -compact -align-top" key={`${id.toString()}th`}>
        <div className="grid__item -span-2 -label">{i18n.t('project.date')}</div>
        <div className="grid__item -span-2 -label">{i18n.t('project.projectNumber')}</div>
        <div className={`grid__item -span-${props.hideClients ? 8 : 4} -label`}>{i18n.t('project.projectName')}</div>
        {!props.hideClients && <div className="grid__item -span-4 -label">{i18n.t('project.clients')}</div>}
        <div className="grid__item -span-2">{`${formatDate(startDate, isoDateHourFormat)} -\n${formatDate(
          endDate,
          isoDateHourFormat
        )}`}</div>
        <div className="grid__item -span-2">{number}</div>
        <div className={`grid__item -span-${props.hideClients ? 8 : 4} -label`}>{name}</div>
        {!props.hideClients && (
          <div className="grid__item -span-4">{clients.length ? clients.map(c => c.name).join('\n') : '-'}</div>
        )}
      </div>
      <div className="grid -compact -align-top -span-12" style={{ paddingTop: 32 }}>
        <div className="grid__item -span-12 -label">{i18n.t('project.remarks')}</div>
        <div className="grid__item tooltip__section-data list__data -span-12">
          <div className="list__cell -tooltip">{remark || '-'}</div>
        </div>
      </div>
      <div className="grid__item -span-12" style={{ fontWeight: 'bold', paddingTop: 32, paddingBottom: 12 }}>
        {i18n.t('project.cargo')}
      </div>
      <div className="grid -compact -align-top">
        <div className="grid__item -span-2 -label">{i18n.t('project.productCategory')}</div>
        <div className="grid__item -span-3 -label">{i18n.t('project.productType')}</div>
        <div className="grid__item -span-3 -label">{i18n.t('project.productSubtype')}</div>
        <div className="grid__item -span-2 -label">{i18n.t('project.quantity')}</div>
        <div className="grid__item -span-2 -label">{i18n.t('project.totalWeightInMT')}</div>
        {cargo.map(
          ({ productCategory, productTypeName, productSubtypeName, quantity, totalWeightInMT, unit }, index) => (
            <React.Fragment key={getCargoItemKey(id, index)}>
              <div className="grid__item -span-2">{productCategory}</div>
              <div className="grid__item -span-3">{productTypeName}</div>
              <div className="grid__item -span-3">{productSubtypeName || '-'}</div>
              <div className="grid__item -span-2">
                {[quantity || 0, unit ? i18n.t(`units.${unit}`) : '-'].join(' ')}
              </div>
              <div className="grid__item -span-2">{`${totalWeightInMT}`}</div>
            </React.Fragment>
          )
        )}
      </div>
    </div>
  );
};

LaborAvailabilityProjectTooltipContent.propTypes = {
  i18n: PropTypes.shape({ t: PropTypes.func.isRequired }).isRequired,
  project: Project.isRequired,
  hideClients: PropTypes.bool.isRequired,
};

export default LaborAvailabilityProjectTooltipContent;
