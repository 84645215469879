import PropTypes from 'prop-types';

const WorkerCall = PropTypes.shape({
  id: PropTypes.number.isRequired,
  calledById: PropTypes.string,
  calledByEmail: PropTypes.string,
  calledByName: PropTypes.string,
  calledOn: PropTypes.string,
  updatedOn: PropTypes.string,
});

export default WorkerCall;
