import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { momentObj } from 'react-moment-proptypes';
import Worker from './models/Worker';
import LaborAvailabilityDay from './LaborAvailabilityDay';
import { availabilityStatusTypeInProgress, availabilityTypeAvailable, daysInWeek } from './constants';
import GroupConfiguration from './models/GroupConfiguration';
import WorkerRank from './models/WorkerRank';
import SiteConfiguration from './models/SiteConfiguration';

export default class LaborAvailabilityWorker extends React.PureComponent {
  static propTypes = {
    afterDeadlineNextEditableDate: momentObj.isRequired,
    dates: PropTypes.arrayOf(momentObj).isRequired,
    groupConfiguration: GroupConfiguration.isRequired,
    i18n: PropTypes.shape({ t: PropTypes.func.isRequired }).isRequired,
    onAvailabilityClick: PropTypes.func.isRequired,
    onCalledClick: PropTypes.func.isRequired,
    onInfractionClick: PropTypes.func.isRequired,
    worker: Worker.isRequired,
    currentUser: PropTypes.bool,
    isDesktopOrLarger: PropTypes.bool,
    isTabletOrLarger: PropTypes.bool,
    isManager: PropTypes.bool,
    isReadonlyManager: PropTypes.bool,
    displayAction: PropTypes.bool,
    dateClickDisabled: PropTypes.bool,
    setDateClickDisabled: PropTypes.func,
    workerRanks: PropTypes.arrayOf(WorkerRank),
    siteConfiguration: PropTypes.shape(SiteConfiguration).isRequired,
  };

  static defaultProps = {
    currentUser: false,
    dateClickDisabled: false,
    displayAction: false,
    isDesktopOrLarger: true,
    isManager: false,
    isTabletOrLarger: false,
    isReadonlyManager: false,
    setDateClickDisabled: () => undefined,
    workerRanks: [],
  };

  fetchAvailability = date => {
    const availability = this.props.worker.availabilities.find(({ date: availabilityDate }) =>
      date.isSame(availabilityDate, 'day')
    );
    if (!availability) {
      if (this.props.groupConfiguration.defaultAvailability === availabilityTypeAvailable) {
        const defaultAvailability = this.props.groupConfiguration.availabilityTypeConfigurations.find(
          ({ isDefault }) => isDefault
        );
        if (defaultAvailability) {
          return {
            availability: defaultAvailability.availabilityType.availability,
            availabilityTypeDescriptionEn: defaultAvailability.availabilityType.descriptionEn,
            availabilityTypeDescriptionFr: defaultAvailability.availabilityType.descriptionFr,
            availabilityTypeNameEn: defaultAvailability.availabilityType.nameEn,
            availabilityTypeNameFr: defaultAvailability.availabilityType.nameFr,
            status: availabilityStatusTypeInProgress,
            isAvailabilityTypeSpecified: true,
          };
        }
      }
      return {
        availability: this.props.groupConfiguration.defaultAvailability,
        status: availabilityStatusTypeInProgress,
      };
    }
    return availability;
  };

  fetchSum = () => {
    let defaultSum = 0;
    if (
      this.props.worker.availabilities.length < daysInWeek &&
      this.props.groupConfiguration.defaultAvailability === availabilityTypeAvailable
    ) {
      defaultSum += daysInWeek - this.props.worker.availabilities.length;
    }

    return (
      defaultSum +
      this.props.worker.availabilities.filter(({ availability }) => availability === availabilityTypeAvailable).length
    );
  };

  render() {
    return (
      <li className="labor-availability-calendar__worker">
        <header
          className={classNames('labor-availability-calendar__worker-label', {
            '-current-user': this.props.currentUser,
            '-current-user-tablet-display': this.props.currentUser && !this.props.isDesktopOrLarger,
            '-tablet-display': !this.props.currentUser && !this.props.isDesktopOrLarger,
          })}>
          <div>
            <div>{this.props.worker.name}</div>
            {this.props.isManager && (
              <div className="labor-availability-calendar__hourWorked">
                {this.props.i18n.t('hoursWorked', { hours: this.props.worker.hoursWorked })}
              </div>
            )}
          </div>
        </header>
        <div
          className={classNames(
            classNames('labor-availability-calendar__worker-days', {
              '-tablet-display':
                !this.props.currentUser && !this.props.isDesktopOrLarger && this.props.isTabletOrLarger,
            })
          )}>
          {this.props.dates.map((date, index) => {
            const dateNumber = date.date();
            const availability = this.fetchAvailability(date);
            return (
              <LaborAvailabilityDay
                afterDeadlineNextEditableDate={this.props.afterDeadlineNextEditableDate}
                i18n={this.props.i18n}
                isDesktopOrLarger={this.props.isDesktopOrLarger}
                isTabletOrLarger={this.props.isTabletOrLarger}
                key={dateNumber}
                availability={availability}
                currentUser={this.props.currentUser}
                date={date}
                onAvailabilityClick={this.props.onAvailabilityClick}
                onCalledClick={this.props.onCalledClick}
                onInfractionClick={this.props.onInfractionClick}
                worker={this.props.worker}
                isManager={this.props.isManager}
                isReadonlyManager={this.props.isReadonlyManager}
                displayAction={this.props.displayAction}
                dateClickDisabled={this.props.dateClickDisabled}
                setDateClickDisabled={this.props.setDateClickDisabled}
                workerRank={this.props.workerRanks[index]}
                groupConfiguration={this.props.groupConfiguration}
                siteConfiguration={this.props.siteConfiguration}
              />
            );
          })}
        </div>
        <footer
          className={classNames('labor-availability-calendar__worker-sum', {
            '-hidden': this.props.groupConfiguration.defaultAvailability !== availabilityTypeAvailable,
          })}>
          {this.fetchSum()}/{daysInWeek - this.props.groupConfiguration.maximumNonAvailabilityDays}
        </footer>
      </li>
    );
  }
}
