import PropTypes from 'prop-types';

const AvailabilityTypes = {
  id: PropTypes.string,
  availability: PropTypes.string,
  nameFr: PropTypes.string,
  nameEn: PropTypes.string,
  descriptionFr: PropTypes.string,
  descriptionEn: PropTypes.string,
};

export default AvailabilityTypes;
