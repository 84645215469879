export const daysInWeek = 7;
export const hoursInDay = 24;
export const minutesInHour = 60;
export const minutesInDay = hoursInDay * minutesInHour;
export const longTermProjectNumberOfDaysDelimiter = 28;
export const longTermProjectNumberOfMinutesDelimiter = minutesInDay * longTermProjectNumberOfDaysDelimiter;

export const projectTypeTerminal = 'Terminal';
export const projectTypeVessel = 'Vessel';
export const projectTypeBarge = 'Barge';
export const projectTypeIT = 'IT';
export const projectTypeAdministration = 'Administration';
export const projectTypeEngineering = 'Engineering';
export const projectTypeMaintenance = 'Maintenance';

export const projectTypes = {
  terminal: projectTypeTerminal,
  vessel: projectTypeVessel,
  barge: projectTypeBarge,
  it: projectTypeIT,
  administration: projectTypeAdministration,
  engineering: projectTypeEngineering,
  maintenance: projectTypeMaintenance,
};

export const availabilityTypeAvailable = 'Available';
export const availabilityTypeNotAvailable = 'NotAvailable';
export const availabilityTypeOnlyCalendarAccess = 'OnlyCalendarAccess';

export const availabilityStatusTypeInProgress = 'InProgress';
export const availabilityStatusTypeRefused = 'Refused';
export const availabilityStatusTypeConfirmed = 'Confirmed';

export const availabilityUnitWeek = 'Week';
export const availabilityUnitMonth = 'Month';

export const availabilityTypes = [availabilityTypeAvailable, availabilityTypeNotAvailable];
export const availabilityUnitTypes = [availabilityUnitWeek, availabilityUnitMonth];
export const availabilityStatusTypes = [
  availabilityStatusTypeInProgress,
  availabilityStatusTypeRefused,
  availabilityStatusTypeConfirmed,
];

export const laborManagerRole = 'labormanager';
export const portManagerRole = 'portmanager';
export const superintendentRole = 'superintendent';
export const adminRole = 'admin';
export const legalaffairsRole = 'legalaffairs';
export const servicemanagerRole = 'servicemanager';
export const uppermanagementRole = 'uppermanagement';

export const debounceOptions = {
  delay: 100,
  maxWait: 1000,
};

export const managerApprobationDateLimitDayKey = 'managerApprobationDateLimitDay';
export const workerChangeDateLimitDayKey = 'workerChangeDateLimitDay';

export const dayLimitKeys = {
  managerApprobationDateLimitDayKey,
  workerChangeDateLimitDayKey,
};

export const managerApprobationDateLimitHourKey = 'managerApprobationDateLimitHour';
export const workerChangeDateLimitHourKey = 'workerChangeDateLimitHour';

export const hourLimitKeys = {
  managerApprobationDateLimitHourKey,
  workerChangeDateLimitHourKey,
};

export const managerDeadlineKey = 'managerDeadline';
export const workerDeadlineKey = 'workerDeadline';
