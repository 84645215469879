import Moment from 'moment';
import PropTypes from 'prop-types';
import { momentObj } from 'react-moment-proptypes';
import React from 'react';
import memoizeOne from 'memoize-one';
import i18next from 'i18next';
import Loader from '@vooban/loader';
import Project from './models/Project';
import {
  availabilityTypeAvailable,
  daysInWeek,
  laborManagerRole,
  portManagerRole,
  superintendentRole,
} from './constants';
import { getProjectsForWeek } from './helpers/calendarHelpers';
import LaborAvailabilityGroup from './LaborAvailabilityGroup';
import LaborAvailabilityProjects from './LaborAvailabilityProjects';
import LaborAvailabilitySums from './LaborAvailabilitySums';
import LaborAvailabilityInformationLine from './LaborAvailabilityInformationLine';
import User from './models/User';
import i18nConfig from './__i18n__';
import { updateMomentLocale } from './helpers/momentHelpers';
import WorkerAvailability from './models/WorkerAvailability';
import SiteConfiguration from './models/SiteConfiguration';

const i18n = i18next.createInstance();
export default class LaborAvailabilityCalendar extends React.PureComponent {
  static propTypes = {
    projects: PropTypes.arrayOf(Project).isRequired,
    startDate: momentObj.isRequired,
    locale: PropTypes.string.isRequired,
    onAvailabilityClick: PropTypes.func.isRequired,
    onCalledClick: PropTypes.func,
    onInfractionClick: PropTypes.func,
    user: User.isRequired,
    isDesktopOrLarger: PropTypes.bool,
    isTabletOrLarger: PropTypes.bool,
    isFetchingProjects: PropTypes.bool.isRequired,
    hideClients: PropTypes.bool,
    showLaborAvailabilityGroup: PropTypes.bool.isRequired,
    displayAction: PropTypes.bool,
    onClickCalendar: PropTypes.func,
    workerAvailability: PropTypes.arrayOf(WorkerAvailability).isRequired,
    dateClickDisabled: PropTypes.bool,
    setDateClickDisabled: PropTypes.func,
    isReadVesselCalendarOnly: PropTypes.bool,
    siteConfiguration: PropTypes.shape(SiteConfiguration).isRequired,
  };

  static defaultProps = {
    displayAction: false,
    dateClickDisabled: false,
    isDesktopOrLarger: false,
    isTabletOrLarger: false,
    onCalledClick: () => undefined,
    onInfractionClick: () => undefined,
    setDateClickDisabled: () => undefined,
    onClickCalendar: () => undefined,
    hideClients: false,
    isReadVesselCalendarOnly: false,
  };

  ranksByDates = new Array(daysInWeek).fill(1);

  state = {
    i18nInitialized: false,
  };

  async componentDidMount() {
    await i18n.init(i18nConfig(this.props.locale));
    updateMomentLocale();
    this.setState({ i18nInitialized: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.locale !== prevProps.locale) {
      i18n.changeLanguage(this.props.locale);
      updateMomentLocale();
    }
  }

  get isManager() {
    return (
      this.props.user.groups &&
      this.props.user.groups.some(group => [laborManagerRole, superintendentRole, portManagerRole].includes(group))
    );
  }

  getDates = memoizeOne(startOfWeek => {
    const dateIterator = Moment(startOfWeek).subtract(1, 'days');
    return Array.from(Array(daysInWeek), () => Moment(dateIterator.add(1, 'day')));
  });

  // eslint-disable-next-line react/sort-comp
  groupsWorkerRanks = memoizeOne(dates => {
    this.ranksByDates.fill(1);

    return this.props.workerAvailability.map(groupConfiguration => this.workersRanks(dates, groupConfiguration));
  });

  workersHaveProposalDayOff = memoizeOne((dates, workerAvailability) =>
    dates.some(date =>
      workerAvailability.some(groupConfiguration =>
        groupConfiguration.workers.some(worker => {
          const workerAvailabilityForDate = worker.availabilities.find(availability =>
            Moment(date).isSame(availability.date, 'day')
          );

          return !!(workerAvailabilityForDate && workerAvailabilityForDate.isProposalDayOff);
        })
      )
    )
  );

  workersRanks = (dates, groupConfiguration) => {
    const ranksByDate = dates.map((date, index) => this.workersRanksByDate(date, groupConfiguration, index));

    return groupConfiguration.workers.map(worker => this.workersRanksByWorker(worker, ranksByDate));
  };

  workersRanksByDate = (date, groupConfiguration, index) => {
    const ranksByDate = [];

    groupConfiguration.workers.forEach(worker => {
      const workerAvailability = worker.availabilities.find(availability =>
        Moment(date).isSame(availability.date, 'day')
      );

      if (workerAvailability && workerAvailability.availability === availabilityTypeAvailable) {
        ranksByDate.push({ rank: this.ranksByDates[index], workerId: worker.id });
        this.ranksByDates[index] += 1;
      } else if (!workerAvailability && groupConfiguration.defaultAvailability === availabilityTypeAvailable) {
        ranksByDate.push({ rank: this.ranksByDates[index], workerId: worker.id });
        this.ranksByDates[index] += 1;
      }
    });

    return ranksByDate;
  };

  // eslint-disable-next-line class-methods-use-this
  workersRanksByWorker = (worker, ranksByDate) =>
    ranksByDate.map(ranks => ranks.find(rank => rank.workerId === worker.id));

  getAvailabilityTypeRequirementByLanguage = availabilityTypeConfiguration => {
    const availabilityTypeInfo = availabilityTypeConfiguration.availabilityType;
    const minMax = `${
      availabilityTypeConfiguration.minimumAvailabilityDays !== 0
        ? `Min: ${availabilityTypeConfiguration.minimumAvailabilityDays}`
        : ''
    } ${
      availabilityTypeConfiguration.maximumAvailabilityDays !== 0
        ? `Max: ${availabilityTypeConfiguration.maximumAvailabilityDays}`
        : ''
    }`;
    if (this.props.locale === 'en') {
      return `${availabilityTypeInfo.descriptionEn} - ${minMax}`;
    }
    return `${availabilityTypeInfo.descriptionFr} - ${minMax}`;
  };

  render() {
    const startOfWeek = Moment(this.props.startDate).startOf('week');
    const dates = this.getDates(startOfWeek);
    const [firstDate] = dates;
    const groupsWorkersRanks = this.groupsWorkerRanks(dates);
    const weekNumber = firstDate.week();
    const weekProjects = getProjectsForWeek(this.props.startDate, this.props.projects);
    const workersHaveProposalDayOff = this.workersHaveProposalDayOff(dates, this.props.workerAvailability);
    const currentUserGroupConfiguration = this.props.workerAvailability.find(groupConfiguration =>
      groupConfiguration.workers.some(
        worker => worker.email.localeCompare(this.props.user.email, undefined, { sensitivity: 'accent' }) === 0
      )
    );

    const currentUserGroupRequirements =
      currentUserGroupConfiguration &&
      currentUserGroupConfiguration.availabilityTypeConfigurations &&
      currentUserGroupConfiguration.availabilityTypeConfigurations.filter(
        availabilityTypeConfiguration =>
          availabilityTypeConfiguration.minimumAvailabilityDays !== 0 ||
          availabilityTypeConfiguration.maximumAvailabilityDays !== 0
      );

    return (
      <>
        {((!this.state.i18nInitialized && i18n.language !== this.props.locale) || this.props.isFetchingProjects) && (
          <Loader />
        )}
        {i18n.language === this.props.locale && (
          <>
            <section className="labor-availability-calendar">
              <time
                key={weekNumber}
                className="labor-availability-calendar__row labor-availability-calendar__week"
                dateTime={`${firstDate.year()}-W${weekNumber}`}>
                {dates.map(date => {
                  const dateNumber = date.date();
                  return (
                    <button
                      disabled
                      type="button"
                      key={dateNumber}
                      className="labor-availability-calendar__day"
                      data-date={dateNumber}>
                      <span className="labor-availability-calendar__weekday -is-mobile">{date.format('dd')[0]}</span>
                      <span className="labor-availability-calendar__weekday -is-desktop">{date.format('ddd')}</span>
                    </button>
                  );
                })}

                <LaborAvailabilityProjects
                  i18n={i18n}
                  onClickCalendar={this.props.onClickCalendar}
                  isDesktopOrLarger={this.props.isDesktopOrLarger}
                  hideClients={this.props.hideClients}
                  projects={weekProjects}
                  startOfWeek={firstDate}
                  endOfWeek={Moment(firstDate).endOf('week')}
                />
              </time>
              {!this.isManager && workersHaveProposalDayOff && (
                <LaborAvailabilityInformationLine i18n={i18n} translationPath="informationLine" isManager={false} />
              )}
              {this.props.showLaborAvailabilityGroup && !this.props.isReadVesselCalendarOnly && (
                <>
                  <ol className="labor-availability-calendar__groups">
                    {this.props.workerAvailability.map(
                      (groupConfiguration, index) =>
                        (currentUserGroupConfiguration === groupConfiguration || this.isManager) && (
                          <LaborAvailabilityGroup
                            language={this.props.locale}
                            i18n={i18n}
                            isFirst={index === 0}
                            isDesktopOrLarger={this.props.isDesktopOrLarger}
                            isTabletOrLarger={this.props.isTabletOrLarger}
                            key={groupConfiguration.id}
                            groupConfiguration={groupConfiguration}
                            groupWorkersRanks={groupsWorkersRanks[index]}
                            dates={dates}
                            onAvailabilityClick={this.props.onAvailabilityClick}
                            onCalledClick={this.props.onCalledClick}
                            onInfractionClick={this.props.onInfractionClick}
                            user={this.props.user}
                            displayAction={this.props.displayAction}
                            dateClickDisabled={this.props.dateClickDisabled}
                            setDateClickDisabled={this.props.setDateClickDisabled}
                            isManager={this.isManager}
                            siteConfiguration={this.props.siteConfiguration}
                          />
                        )
                    )}
                  </ol>
                  {this.isManager && (
                    <LaborAvailabilitySums
                      workerAvailability={this.props.workerAvailability}
                      dates={dates}
                      i18n={i18n}
                    />
                  )}
                </>
              )}
            </section>
            {!this.isManager && (
              <div className="labor-availability-calendar__row">
                {currentUserGroupConfiguration &&
                  (currentUserGroupRequirements.length !== 0 ||
                    (this.props.locale === 'en'
                      ? currentUserGroupConfiguration.generalRequirementEn
                      : currentUserGroupConfiguration.generalRequirementFr)) && (
                    <>
                      <div className="labor-availability-calendar__requirement -label">{i18n.t('requirement')} :</div>
                      <div className="labor-availability-calendar__requirement -value">
                        {this.props.locale === 'en'
                          ? currentUserGroupConfiguration.generalRequirementEn
                          : currentUserGroupConfiguration.generalRequirementFr}
                      </div>
                      {currentUserGroupRequirements.map(availabilityTypeConfiguration => (
                        <div className="labor-availability-calendar__requirement -value">
                          {this.getAvailabilityTypeRequirementByLanguage(availabilityTypeConfiguration)}
                        </div>
                      ))}
                    </>
                  )}
              </div>
            )}
          </>
        )}
      </>
    );
  }
}
