import enTranslation from './en';
import frTranslation from './fr';

const i18nConfig = lng => ({
  lng,
  fallbackLng: 'en',
  resources: {
    en: { translation: enTranslation },
    fr: { translation: frTranslation },
  },
});

export default i18nConfig;
