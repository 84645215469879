import VolumeEnum from './VolumeEnum';
import MassEnum from './MassEnum';
import GenericUnitEnum from './GenericUnitEnum';
import DistanceUnitEnum from './DistanceUnitEnum';

export default {
  ...MassEnum,
  ...VolumeEnum,
  ...GenericUnitEnum,
  ...DistanceUnitEnum,
};
