import PropTypes from 'prop-types';
import WorkerAvailability from './WorkerAvailability';

const Worker = PropTypes.shape({
  auth0Id: PropTypes.string,
  availabilities: PropTypes.arrayOf(WorkerAvailability).isRequired,
  classifications: PropTypes.arrayOf(PropTypes.string),
  communicationPreference: PropTypes.string,
  email: PropTypes.string.isRequired,
  groupConfigurationId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  invitedByEmail: PropTypes.string,
  invitedByName: PropTypes.string,
  invitedOn: PropTypes.string,
  languagePreference: PropTypes.string,
  name: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  proposalDaysOffIds: PropTypes.arrayOf(PropTypes.number),
  sofeNumber: PropTypes.string,
  totalAvailableDays: PropTypes.number.isRequired,
  totalNonAvailableDays: PropTypes.number.isRequired,
  updatedOn: PropTypes.string.isRequired,
  hoursWorked: PropTypes.number.isRequired,
});

export default Worker;
