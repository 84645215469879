export default {
  charterPartyTerms: {
    freeIn: 'Free In',
    freeOut: 'Free Out',
    linerIn: 'Liner In',
    linerOut: 'Liner Out',
    linesmenOnly: 'Linesmen Only',
  },
  packagings: {
    BarrelDrum: 'In barrel drums',
    BigBag: 'In big bags',
    ContainerFlatRack10: "Container - Flat Rack - 10'",
    ContainerFlatRack20: "Container - Flat Rack - 20'",
    ContainerFlatRack40: "Container - Flat Rack - 40'",
    ContainerFlatRack40HC: "Container - Flat Rack - 40' High Cube",
    ContainerFlatRack53WHC: "Container - Flat Rack - 53' Wide High Cube",
    ContainerReefer10: "Container - Reefer - 10'",
    ContainerReefer20: "Container - Reefer - 20'",
    ContainerReefer40: "Container - Reefer - 40'",
    ContainerReefer40HC: "Container - Reefer - 40' High Cube",
    ContainerReefer53WHC: "Container - Reefer - 53' Wide High Cube",
    ContainerRegular10: "Container - Regular - 10'",
    ContainerRegular20: "Container - Regular - 20'",
    ContainerRegular40: "Container - Regular - 40'",
    ContainerRegular40HC: "Container - Regular - 40' High Cube",
    ContainerRegular53WHC: "Container - Regular - 53' Wide High Cube",
    Crate: 'On crate',
    NoneLoose: 'Loose (no packaging)',
    Pallet: 'On pallet',
    StrappedBundle: 'In strapped bundle',
    Unknown: 'In unknown packaging',
  },
  units: {
    BoardFoot: 'FBM',
    CubicBoard: 'cbm',
    CubicFoot: 'cu.ft.',
    CubicMeter: 'm³',
    CubicYard: 'cu.yd.',
    Day: 'day',
    Feet: 'ft',
    FreightTon: 'freight ton',
    Hour: 'hour',
    kg: 'kg',
    KilogramPerCubicMeter: 'kg/m³',
    lb: 'lb',
    Liter: 'liter',
    Month: 'month',
    MT: 'MT',
    Meter: 'm',
    NT: 'NT',
    PoundPerCubicFoot: 'lb/ft³',
    PoundPerCubicYard: 'lb/yd³',
    Shift: 'shift',
    SquareFoot: 'sq.ft.',
    SquareMeter: 'm²',
    TonnePerCubicMeter: 'MT/m³',
    TwentyFootEquivalentUnit: 'TEU',
    Units: 'units',
    Week: 'week',
  },
};
