import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@vooban/icon';
import { availabilityTypeAvailable, availabilityTypeNotAvailable } from './constants';
import WorkerRank from './models/WorkerRank';
import WorkerAvailability from './models/WorkerAvailability';

const LaborAvailabilityDayWorker = ({
  availability,
  currentUser,
  i18n,
  isRefused,
  isDesktopOrLarger,
  isRankVisible,
  workerRank,
}) => {
  const rank = workerRank && isRankVisible ? ` (${workerRank.rank})` : '';
  const availabilityTypeName =
    i18n.language === 'en' && availability.availabilityTypeNameEn
      ? availability.availabilityTypeNameEn
      : availability.availabilityTypeNameFr;
  const availabilityValueContent = availability.isAvailabilityTypeSpecified
    ? availabilityTypeName
    : i18n.t(`availabilityTypes.${availability.availability}`);

  let content = availabilityValueContent;

  if (availability.availability === availabilityTypeAvailable) {
    if (isRefused && isDesktopOrLarger) {
      content = (
        <>
          {availabilityValueContent}
          <Icon id="warn" className="-warn" />
        </>
      );
    } else {
      content = currentUser || isDesktopOrLarger ? `${availabilityValueContent}${rank}` : '✓';
    }
  } else if (availability.availability === availabilityTypeNotAvailable) {
    if (isRefused && isDesktopOrLarger) {
      content = (
        <>
          {availabilityValueContent}
          <Icon id="warn" className="-warn" />
        </>
      );
    }
  }

  return <div className="labor-availability-day__worker">{content}</div>;
};

LaborAvailabilityDayWorker.propTypes = {
  availability: WorkerAvailability.isRequired,
  currentUser: PropTypes.bool.isRequired,
  i18n: PropTypes.shape({ t: PropTypes.func, language: PropTypes.string }).isRequired,
  isRefused: PropTypes.bool.isRequired,
  isDesktopOrLarger: PropTypes.bool.isRequired,
  isRankVisible: PropTypes.bool,
  workerRank: WorkerRank,
};

LaborAvailabilityDayWorker.defaultProps = {
  workerRank: undefined,
  isRankVisible: false,
};

export default LaborAvailabilityDayWorker;
