import PropTypes from 'prop-types';

const CargoItem = {
  productCategory: PropTypes.string,
  productType: PropTypes.string,
  productTypeName: PropTypes.string,
  productSubtypeId: PropTypes.number,
  productSubtypeName: PropTypes.string,
  quantity: PropTypes.number,
  totalWeightInMT: PropTypes.number,
  unit: PropTypes.string,
};

export default CargoItem;
