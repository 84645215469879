import Tooltip from '@vooban/tooltip';
import Icon from '@vooban/icon';
import PropTypes from 'prop-types';
import React from 'react';

const ManagerButton = ({ iconId, className, tooltipContent, onClick, disabled }) => {
  return (
    <Tooltip
      delay={[500, 0]}
      className="labor-availability-calendar__worker-availability-day-manager-button-tooltip"
      content={tooltipContent}>
      <button
        type="button"
        className={`labor-availability-calendar__worker-availability-day-manager-button ${className}`}
        onClick={disabled ? undefined : onClick}>
        <Icon id={iconId} />
      </button>
    </Tooltip>
  );
};

ManagerButton.propTypes = {
  iconId: PropTypes.string.isRequired,
  className: PropTypes.string,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ManagerButton.defaultProps = {
  className: '',
  disabled: false,
};

export default ManagerButton;
