const availabilityTypes = {
  Available: 'Disponible',
  NotAvailable: 'Non disponible',
};

const availabilityStatusTypes = {
  Confirmed: 'Confirmé',
  InProgress: 'En cours',
  Refused: 'Refusé',
};

const informationLine = {
  infraction: 'Le travailleur est en infraction',
  nonAvailabilityPrivilege: 'Privilège de non disponibilité',
  refusedAvailability: 'La disponibilité/non disponibilité a été refusée',
  workerCalled: 'Le travailleur a été appelé',
};

const project = {
  cargo: 'Cargo',
  clients: 'Clients',
  date: 'Date',
  productCategory: 'Catégorie du produit',
  productSubtype: 'Sous-type du produit',
  productType: 'Type du produit',
  projectName: 'Nom du projet',
  projectNumber: 'Numéro du projet',
  quantity: 'Quantité',
  remarks: 'Remarque',
  totalWeightInMT: 'Poids total (TM)',
};

const units = { MT: 'TM' };

export default {
  availabilityStatusTypes,
  availabilityTypes,
  informationLine,
  periodApproved: 'Cette période a été approuvée.',
  periodHasNotYetBeenSubmitted: "La période n'a pas encore été soumise.",
  periodUnderApproval: "Cette période est en cours d'approbation.",
  project,
  requiredDays: 'Jours Requis',
  units,
  workers: '{{quantity}} travailleurs',
  requirement: 'Exigences',
};
