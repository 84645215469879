import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '@vooban/icon';

const LaborAvailabilityInformationLine = ({ i18n, translationPath, isManager }) => {
  return (
    <div
      className={classNames('labor-availability-calendar__information-line', {
        '-bottom-spaced': !isManager,
      })}>
      <div className="icon-container">
        <Icon id="lock" className="-lock" />
        <span className="legend">{i18n.t(`${translationPath}.nonAvailabilityPrivilege`)}</span>
      </div>
      {isManager && (
        <>
          <div className="icon-container -left-margined">
            <Icon id="warn" className="-warn" />
            <span className="legend">{i18n.t(`${translationPath}.refusedAvailability`)}</span>
          </div>
          <div className="icon-container -left-margined">
            <Icon id="call-end" className="-called" />
            <span className="legend">{i18n.t(`${translationPath}.workerCalled`)}</span>
          </div>
          <div className="icon-container -left-margined">
            <Icon id="local-police" className="-infraction" />
            <span className="legend">{i18n.t(`${translationPath}.infraction`)}</span>
          </div>
        </>
      )}
    </div>
  );
};

LaborAvailabilityInformationLine.propTypes = {
  i18n: PropTypes.shape({ t: PropTypes.func }).isRequired,
  translationPath: PropTypes.string.isRequired,
  isManager: PropTypes.bool.isRequired,
};

export default LaborAvailabilityInformationLine;
