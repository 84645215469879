export default {
  units: {
    BoardFoot: 'FBM',
    CubicBoard: 'cbm',
    CubicFoot: 'cu.ft.',
    CubicMeter: 'm³',
    CubicYard: 'cu.yd.',
    Day: 'jour',
    FreightTon: 'tonne fret',
    Hour: 'heure',
    Kilogram: 'kg',
    KilogramPerCubicMeter: 'kg/m³',
    Liter: 'litre',
    MetricTon: 'MT',
    Month: 'mois',
    NetTon: 'NT',
    Pound: 'lb',
    PoundPerCubicFoot: 'lb/ft³',
    PoundPerCubicYard: 'lb/yd³',
    TonnePerCubicMeter: 'MT/m³',
    Units: 'unités',
    Week: 'semaine',
  },
};
