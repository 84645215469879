import PropTypes from 'prop-types';

const AvailabilityTypeConfigurations = {
  id: PropTypes.number,
  groupConfigurationId: PropTypes.number,
  availabilityTypeId: PropTypes.number,
  minimumAvailabilityDays: PropTypes.number,
  maximumAvailabilityDays: PropTypes.number,
  isDefault: PropTypes.bool,
};

export default AvailabilityTypeConfigurations;
