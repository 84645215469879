import PropTypes from 'prop-types';
import AvailabilityTypes from './AvailabilityTypes';

const SiteConfiguration = PropTypes.shape({
  id: PropTypes.string,
  allowWorkerAvailability: PropTypes.bool,
  availabilityTypes: PropTypes.arrayOf(PropTypes.shape(AvailabilityTypes)),
});

export default SiteConfiguration;
