import _debounce from 'lodash.debounce';
import { debounceOptions } from '../laborAvailability/constants';

/**
 * @param {string | boolean | number | ArrayLike<any> | {[s: string]: any;} } obj
 * @returns {boolean}
 */
export const isEmptyDeep = obj => {
  if (obj == null || obj === '') return true;
  const keys = Object.keys(obj);
  const type = typeof obj;
  if (type === 'object' && keys.length === 0) return true;
  if (type === 'object' && keys.length > 0)
    return Object.entries(obj)
      .map(([, v]) => isEmptyDeep(v))
      .every(Boolean);
  return false;
};

/**
 * Empty values are considered as follow :
 * Null or Undefined.
 * Empty strings.
 * Object filled with empty values (recursive)
 * Array filled with empty values (recursive)
 * @param {{ [s: string]: any; }} obj Any object or array with possible empty values
 * @returns {ArrayLike<any> | {[s: string]: any;} } Object/Array with same structure without empty string, empty object, empty array and null/undefined value
 */
export const removeEmptyDeep = obj => {
  if (Array.isArray(obj)) {
    return obj.filter(x => !isEmptyDeep(x)).map(v => (v && typeof v === 'object' ? removeEmptyDeep(v) : v));
  }
  return Object.entries(obj)
    .map(([k, v]) => [k, v && typeof v === 'object' ? removeEmptyDeep(v) : v])
    .reduce((acc, [k, v]) => {
      if (isEmptyDeep(v)) return acc;
      acc[k] = v;
      return acc;
    }, {});
};

export const debounce = (func, delay = debounceOptions.delay, options = { maxWait: debounceOptions.maxWait }) =>
  _debounce(func, delay, options);
