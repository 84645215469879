import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'moment';
import { momentObj } from 'react-moment-proptypes';
import Tooltip from '@vooban/tooltip';
import Icon from '@vooban/icon';
import GroupConfiguration from './models/GroupConfiguration';
import LaborAvailabilityWorker from './LaborAvailabilityWorker';
import User from './models/User';
import {
  availabilityTypeAvailable,
  managerDeadlineKey,
  workerDeadlineKey,
  adminRole,
  legalaffairsRole,
  servicemanagerRole,
  uppermanagementRole,
} from './constants';
import { getNextEditableDate } from './helpers/momentHelpers';
import WorkerRank from './models/WorkerRank';
import SiteConfiguration from './models/SiteConfiguration';

export default class LaborAvailabilityGroup extends React.PureComponent {
  static propTypes = {
    language: PropTypes.string.isRequired,
    i18n: PropTypes.shape({ t: PropTypes.func.isRequired }).isRequired,
    groupConfiguration: GroupConfiguration.isRequired,
    onAvailabilityClick: PropTypes.func.isRequired,
    onCalledClick: PropTypes.func.isRequired,
    onInfractionClick: PropTypes.func.isRequired,
    user: User.isRequired,
    dates: PropTypes.arrayOf(momentObj).isRequired,
    groupWorkersRanks: PropTypes.arrayOf(PropTypes.arrayOf(WorkerRank)),
    isDesktopOrLarger: PropTypes.bool,
    isFirst: PropTypes.bool,
    isTabletOrLarger: PropTypes.bool,
    displayAction: PropTypes.bool,
    dateClickDisabled: PropTypes.bool,
    setDateClickDisabled: PropTypes.func,
    isManager: PropTypes.bool,
    siteConfiguration: PropTypes.shape(SiteConfiguration).isRequired,
  };

  static defaultProps = {
    dateClickDisabled: false,
    displayAction: false,
    groupWorkersRanks: [],
    isDesktopOrLarger: true,
    isFirst: false,
    isTabletOrLarger: false,
    isManager: false,
    setDateClickDisabled: () => undefined,
  };

  dateTimeNow = Moment();

  get isReadonlyManager() {
    return (
      this.props.user.groups &&
      this.props.user.groups.some(group =>
        [adminRole, legalaffairsRole, servicemanagerRole, uppermanagementRole].includes(group)
      )
    );
  }

  get managerDeadline() {
    return Moment(this.props.groupConfiguration[managerDeadlineKey]);
  }

  get workerDeadline() {
    return Moment(this.props.groupConfiguration[workerDeadlineKey]);
  }

  get canManagerEditWeek() {
    const weekEditable = this.managerDeadline.add(1, 'weeks').isSame(this.props.dates[0], 'week');

    return (
      weekEditable &&
      (this.managerDeadline.isSame(this.props.dates[0], 'week') || this.workerDeadline <= this.dateTimeNow)
    );
  }

  get deadline() {
    if (this.props.isManager) return this.managerDeadline;

    return this.workerDeadline;
  }

  get afterDeadlineNextEditableDate() {
    if (this.props.isManager) return this.deadline.startOf('week');

    return getNextEditableDate(this.dateTimeNow, this.deadline);
  }

  get isWeekEditable() {
    if (!this.props.dates.length) return false;
    if (this.props.isManager) return this.canManagerEditWeek;
    return this.props.dates[0] >= this.afterDeadlineNextEditableDate;
  }

  get isBeforeManagerDeadline() {
    if (!this.props.dates.length) return false;

    const workerWeekBeforeEditableDate = this.afterDeadlineNextEditableDate.subtract(1, 'weeks');
    const managerWeekBeforeDeadlineDate = Moment(this.managerDeadline).isSame(this.props.dates[0], 'week')
      ? this.managerDeadline.subtract(1, 'weeks')
      : this.managerDeadline;

    if (!Moment(workerWeekBeforeEditableDate).isSame(this.props.dates[0], 'week')) return false;
    return this.dateTimeNow <= managerWeekBeforeDeadlineDate;
  }

  get calendarStatus() {
    if (this.props.isManager) {
      if (
        this.props.dates[0] >= this.managerDeadline ||
        (this.dateTimeNow <= this.workerDeadline && this.props.dates[0].isSame(this.workerDeadline, 'week'))
      ) {
        return this.props.i18n.t('periodHasNotYetBeenSubmitted');
      }
      return this.props.i18n.t('periodApproved');
    }

    return this.isBeforeManagerDeadline
      ? this.props.i18n.t('periodUnderApproval')
      : this.props.i18n.t('periodApproved');
  }

  get isCurrentUserGroup() {
    return this.props.groupConfiguration.workers.some(worker => this.isCurrentUser(worker));
  }

  isCurrentUser = worker =>
    this.props.isManager ||
    this.props.user.email.localeCompare(worker.email, undefined, { sensitivity: 'accent' }) === 0;

  // eslint-disable-next-line class-methods-use-this
  getRequirementsList = availabilityTypeConfiguration => {
    const availabilityTypeInfo = availabilityTypeConfiguration.availabilityType;
    let requirement;
    const minMax = `${
      availabilityTypeConfiguration.minimumAvailabilityDays !== 0
        ? `Min: ${availabilityTypeConfiguration.minimumAvailabilityDays}`
        : ''
    } ${
      availabilityTypeConfiguration.maximumAvailabilityDays !== 0
        ? `Max: ${availabilityTypeConfiguration.maximumAvailabilityDays}`
        : ''
    }`;
    if (availabilityTypeInfo.descriptionFr) {
      requirement = `${availabilityTypeInfo.descriptionFr} - ${minMax}`;
    } else {
      requirement = `${availabilityTypeInfo.descriptionEn} - ${minMax}`;
    }
    return <li className="labor-availability-calendar__requirement -tooltip-value">{requirement}</li>;
  };

  render() {
    return (
      <li className="labor-availability-calendar__row">
        {(this.isCurrentUserGroup || this.isReadonlyManager) && (
          <header className="labor-availability-calendar__group-labels">
            {this.props.isManager ? (
              <div style={{ display: 'flex' }}>
                {this.props.groupConfiguration.name}
                <Tooltip
                  disabled={
                    (this.props.groupConfiguration.generalRequirementEn ||
                      this.props.groupConfiguration.generalRequirementFr) &&
                    this.props.groupConfiguration.availabilityTypeConfigurations.length === 0
                  }
                  content={
                    <div>
                      <div className="labor-availability-calendar__requirement -tooltip-label">Requirements:</div>
                      <ul style={{ listStyle: 'initial' }}>
                        {(this.props.groupConfiguration.generalRequirementEn ||
                          this.props.groupConfiguration.generalRequirementFr) && (
                          <li className="labor-availability-calendar__requirement -tooltip-value">
                            {this.props.groupConfiguration.generalRequirementFr
                              ? this.props.groupConfiguration.generalRequirementFr
                              : this.props.groupConfiguration.generalRequirementEn}
                          </li>
                        )}
                        {this.props.groupConfiguration.availabilityTypeConfigurations &&
                          this.props.groupConfiguration.availabilityTypeConfigurations
                            .filter(
                              availabilityTypeConfiguration =>
                                availabilityTypeConfiguration.minimumAvailabilityDays !== 0 ||
                                availabilityTypeConfiguration.maximumAvailabilityDays !== 0
                            )
                            .map(availabilityTypeConfiguration =>
                              this.getRequirementsList(availabilityTypeConfiguration)
                            )}
                      </ul>
                    </div>
                  }>
                  <Icon id="info--border" className="labor-availability-calendar__requirement -tooltip-icon" />
                </Tooltip>
              </div>
            ) : (
              <div>{this.props.groupConfiguration.name}</div>
            )}
            {!this.isWeekEditable && (
              <div className="labor-availability-calendar__period-approved">{this.calendarStatus}</div>
            )}
            {this.props.isFirst && (
              <div className="labor-availability-calendar__required-days">{this.props.i18n.t('requiredDays')}</div>
            )}
            {!this.props.isFirst && this.props.isDesktopOrLarger && <div />}
          </header>
        )}
        <ol className="labor-availability-calendar__workers">
          {this.props.groupConfiguration.workers.map((worker, index) => {
            if (this.isCurrentUser(worker) || this.isReadonlyManager) {
              return (
                <LaborAvailabilityWorker
                  afterDeadlineNextEditableDate={this.afterDeadlineNextEditableDate}
                  i18n={this.props.i18n}
                  isDesktopOrLarger={this.props.isDesktopOrLarger}
                  isTabletOrLarger={this.props.isTabletOrLarger}
                  key={worker.id}
                  worker={worker}
                  dates={this.props.dates}
                  groupConfiguration={this.props.groupConfiguration}
                  currentUser
                  isManager={this.props.isManager}
                  isReadonlyManager={this.isReadonlyManager}
                  onAvailabilityClick={this.props.onAvailabilityClick}
                  onCalledClick={this.props.onCalledClick}
                  onInfractionClick={this.props.onInfractionClick}
                  displayAction={this.props.displayAction}
                  dateClickDisabled={this.props.dateClickDisabled}
                  setDateClickDisabled={this.props.setDateClickDisabled}
                  workerRanks={this.props.groupWorkersRanks[index]}
                  siteConfiguration={this.props.siteConfiguration}
                />
              );
            }
            return null;
          })}
        </ol>
        {(this.isCurrentUserGroup || this.isReadonlyManager) && (
          <ol className="labor-availability-calendar__group-sums">
            {this.props.dates.map(date => {
              const quantity = this.props.groupConfiguration.workers.filter(({ availabilities }) => {
                let availabilityStatus;
                if (availabilities.length !== 0) {
                  const sameDayAvailability = availabilities.find(availability =>
                    Moment(date).isSame(availability.date, 'day')
                  );
                  if (sameDayAvailability) {
                    availabilityStatus = sameDayAvailability.availability;
                  } else {
                    availabilityStatus = this.props.groupConfiguration.defaultAvailability;
                  }
                } else {
                  availabilityStatus = this.props.groupConfiguration.defaultAvailability;
                }
                return availabilityStatus === availabilityTypeAvailable;
              }).length;
              return (
                <li className="labor-availability-calendar__group-sum" key={date}>
                  {this.props.i18n.t('workers', { quantity })}
                </li>
              );
            })}
          </ol>
        )}
      </li>
    );
  }
}
