import PropTypes from 'prop-types';
import CargoItem from './CargoItem';
import { projectTypes } from '../constants';

export const OperationTypeTerms = ['loading', 'unloading', 'handling', 'linesmenOnly', 'service'];

const Project = PropTypes.shape({
  id: PropTypes.string,
  cargo: PropTypes.arrayOf(PropTypes.exact(CargoItem)),
  name: PropTypes.string,
  siteName: PropTypes.string,
  number: PropTypes.string,
  projectType: PropTypes.oneOf(Object.values(projectTypes)),
  operationType: PropTypes.oneOf(OperationTypeTerms),
  remark: PropTypes.string,
});

export default Project;
