const availabilityTypes = {
  Available: 'Available',
  NotAvailable: 'Unavailable',
};

const availabilityStatusTypes = {
  Confirmed: 'Confirmed',
  InProgress: 'In Progress',
  Refused: 'Refused',
};

const informationLine = {
  infraction: 'The worker is in infraction',
  nonAvailabilityPrivilege: 'Unavailability Privilege',
  refusedAvailability: 'The availability/unavailability has been refused',
  workerCalled: 'The worker has been called',
};

const project = {
  cargo: 'Cargo',
  clients: 'Clients',
  date: 'Date',
  productCategory: 'Product Category',
  productSubtype: 'Product Subtype',
  productType: 'Product Type',
  projectName: 'Project Name',
  projectNumber: 'Project Number',
  quantity: 'Quantity',
  remarks: 'Remark',
  totalWeightInMT: 'Total Weight (MT)',
};

const units = { MT: 'MT' };

const managerActions = {
  changeAvailability: 'Change Availability',
  workerAvailable: 'Worker Available',
  workerCalled: 'Worker Called',
  workerNotAvailableByDefault: 'Cannot change a worker availability if they are unavailable by default.',
  workerInfraction: 'Worker Infraction',
  workerUnavailable: 'Worker Unavailable',
  workerNotEditable: 'Cannot change a worker availability because the deadline has passed.',
};

export default {
  availabilityStatusTypes,
  availabilityTypes,
  hoursWorked: 'Hours worked: {{hours}}',
  informationLine,
  managerActions,
  periodApproved: 'This period has been approved.',
  periodHasNotYetBeenSubmitted: 'Period has not yet been submitted.',
  periodUnderApproval: 'This period is under approval.',
  project,
  requiredDays: 'Required Days',
  units,
  workers: '{{quantity}} workers',
  requirement: 'Requirements',
};
