import Moment from 'moment';

// eslint-disable-next-line
export const getMomentExcludingMidnight = momentDate =>
  momentDate.hours() === 0 && momentDate.minutes() === 0 ? momentDate.add(-1, 'millisecond') : momentDate;

export const getNextEditableDate = (dateTimeNow, deadline) => {
  if (dateTimeNow <= deadline) {
    return deadline.add(1, 'weeks').startOf('week');
  }
  return deadline.add(2, 'weeks').startOf('week');
};

export const isoDateFormat = 'YYYY-MM-DD';
export const isoFullDateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
export const isoDateHourFormat = 'YYYY-MM-DD HH:mm';

export const updateMomentLocale = () => {
  Moment.updateLocale('en', {
    week: {
      dow: 0, // First day of week is Sunday
      doy: 6, // First week of year must contain 1 January (7 + 0 - 1)
    },
  });
};

export const formatDate = (date, format) => Moment(date, [isoDateFormat, isoFullDateFormat]).format(format);
