/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { momentObj } from 'react-moment-proptypes';
import moment from 'moment';
import Icon from '@vooban/icon';
import {
  availabilityStatusTypeConfirmed,
  availabilityStatusTypeRefused,
  availabilityTypeAvailable,
  availabilityTypeNotAvailable,
} from './constants';
import Worker from './models/Worker';
import WorkerRank from './models/WorkerRank';
import LaborAvailabilityDayWorker from './LaborAvailabilityDayWorker';
import LaborAvailabilityDayManager from './LaborAvailabilityDayManager';
import WorkerAvailability from './models/WorkerAvailability';
import { debounce } from '../helpers/objectHelpers';
import GroupConfiguration from './models/GroupConfiguration';
import SiteConfiguration from './models/SiteConfiguration';

export default class LaborAvailabilityDay extends React.PureComponent {
  static propTypes = {
    afterDeadlineNextEditableDate: momentObj.isRequired,
    availability: WorkerAvailability.isRequired,
    date: momentObj.isRequired,
    i18n: PropTypes.shape({ t: PropTypes.func.isRequired }).isRequired,
    onAvailabilityClick: PropTypes.func.isRequired,
    onCalledClick: PropTypes.func.isRequired,
    onInfractionClick: PropTypes.func.isRequired,
    worker: Worker.isRequired,
    currentUser: PropTypes.bool,
    isDesktopOrLarger: PropTypes.bool,
    isTabletOrLarger: PropTypes.bool,
    isManager: PropTypes.bool,
    isReadonlyManager: PropTypes.bool,
    displayAction: PropTypes.bool,
    dateClickDisabled: PropTypes.bool,
    setDateClickDisabled: PropTypes.func,
    workerRank: WorkerRank,
    groupConfiguration: GroupConfiguration.isRequired,
    siteConfiguration: PropTypes.shape(SiteConfiguration).isRequired,
  };

  static defaultProps = {
    currentUser: false,
    dateClickDisabled: false,
    displayAction: false,
    isDesktopOrLarger: true,
    isManager: false,
    isTabletOrLarger: false,
    isReadonlyManager: false,
    setDateClickDisabled: () => undefined,
    workerRank: undefined,
  };

  state = {
    showAction: false,
  };

  get isAvailable() {
    return this.props.availability.availability === availabilityTypeAvailable;
  }

  get isDateEditable() {
    return this.props.date >= this.props.afterDeadlineNextEditableDate || !this.isDateInEditableTimeSpan;
  }

  get isRefused() {
    return this.props.availability.status && this.props.availability.status === availabilityStatusTypeRefused;
  }

  get isNotAvailable() {
    return this.props.availability.availability === availabilityTypeNotAvailable;
  }

  get isOtherWorker() {
    return !this.props.isManager && !this.props.currentUser;
  }

  get isWorkerMobileOtherUsers() {
    return this.isOtherWorker && !this.props.isTabletOrLarger && !this.props.isDesktopOrLarger;
  }

  get isWorkerHiddenOtherUsers() {
    return this.isOtherWorker && !this.props.isReadonlyManager && !this.isDateEditable;
  }

  get isWorkerTabletOtherUsers() {
    return this.isOtherWorker && this.props.isTabletOrLarger && !this.props.isDesktopOrLarger;
  }

  get defaultUnavailableManagerCannotEdit() {
    return (
      this.props.groupConfiguration.defaultAvailability === availabilityTypeNotAvailable &&
      this.props.isManager &&
      !(this.isAvailable || this.isRefused)
    );
  }

  get isDateInEditableTimeSpan() {
    const twoDaysAfterToday = moment()
      .add(2, 'days')
      .startOf('day');

    return this.props.isManager ? this.props.date > twoDaysAfterToday : true;
  }

  get shouldDisableButton() {
    return (
      (!this.props.currentUser ||
        this.props.availability.status === availabilityStatusTypeConfirmed ||
        !this.isDateEditable ||
        !this.isDateInEditableTimeSpan) &&
      !this.props.isManager
    );
  }

  get handleAvailabilityClick() {
    return debounce(() => {
      this.props.onAvailabilityClick(
        this.props.worker,
        this.props.availability,
        this.props.date,
        this.props.groupConfiguration
      );
      this.props.setDateClickDisabled(true);
    });
  }

  get handleCallClick() {
    return debounce(() => {
      this.props.onCalledClick(this.props.availability);
      this.props.setDateClickDisabled(true);
    });
  }

  get handleInfractionClick() {
    return debounce(() => {
      this.props.onInfractionClick(this.props.availability);
      this.props.setDateClickDisabled(true);
    });
  }

  onMouseOver = () => {
    if (this.props.displayAction && !this.props.dateClickDisabled) {
      this.setState({
        showAction: true,
      });
    }
  };

  onMouseLeave = () => {
    if (this.props.displayAction && this.state.showAction) {
      this.setState({
        showAction: false,
      });
    }
    if (this.props.dateClickDisabled) {
      this.props.setDateClickDisabled(false);
    }
  };

  get shouldShowManagerButtons() {
    return this.props.isManager && this.state.showAction;
  }

  render() {
    return (
      <>
        {this.isWorkerHiddenOtherUsers ? (
          <button
            type="button"
            className={classNames('labor-availability-calendar__worker-availability-day focus:outline-none', {
              '-hidden': true,
            })}
            disabled={this.shouldDisableButton}
          />
        ) : (
          <button
            type="button"
            className={classNames('labor-availability-calendar__worker-availability-day focus:outline-none', {
              '-available': this.isAvailable,
              '-available-no-click': this.isAvailable && this.shouldDisableButton,
              '-bold': this.isWorkerMobileOtherUsers || this.isWorkerTabletOtherUsers,
              '-current-user-tablet-display': this.props.currentUser && !this.props.isDesktopOrLarger,
              '-non-available': this.isNotAvailable,
              '-non-available-no-click': this.isNotAvailable && this.shouldDisableButton,
              '-non-editable':
                (!this.isDateEditable || this.isOtherWorker || !this.isDateInEditableTimeSpan) &&
                !this.state.showAction,
              '-other-users-tablet-display': this.isWorkerTabletOtherUsers,
              '-refused': this.isRefused && (this.props.isDesktopOrLarger || this.props.currentUser),
              '-tablet-display': !this.props.currentUser && !this.props.isDesktopOrLarger,
              '-hover-disabled': this.props.dateClickDisabled,
            })}
            disabled={this.shouldDisableButton}
            onClick={this.props.isManager ? undefined : this.handleAvailabilityClick}
            onMouseLeave={this.onMouseLeave}
            onMouseOver={this.onMouseOver}>
            <div
              className={classNames('labor-availability-calendar__worker-availability-day__icon-column -left', {
                '-hidden': this.shouldShowManagerButtons,
              })}>
              {this.props.availability.isProposalDayOff && (
                <div className="labor-availability-calendar__worker-availability-day__corner-container -lock">
                  <Icon id="lock" className="-corner-icon -lock" />
                </div>
              )}
              <div /> {/* place a future icon here */}
            </div>
            <div
              className={classNames('labor-availability-calendar__worker-availability-day__inner', {
                '-manager': this.shouldShowManagerButtons,
              })}>
              {this.props.isManager ? (
                <LaborAvailabilityDayManager
                  availability={this.props.availability}
                  i18n={this.props.i18n}
                  isDesktopOrLarger={this.props.isDesktopOrLarger}
                  isRefused={this.isRefused}
                  displayAction={this.state.showAction}
                  onAvailabilityClick={this.handleAvailabilityClick}
                  onCalledClick={this.handleCallClick}
                  onInfractionClick={this.handleInfractionClick}
                  isEditable={this.isDateInEditableTimeSpan}
                  isAvailable={this.isAvailable}
                  isNotAvailable={this.isNotAvailable}
                  isUnavailableByDefault={this.defaultUnavailableManagerCannotEdit}
                  workerPhoneNumber={this.props.worker.phoneNumber}
                  siteConfiguration={this.props.siteConfiguration}
                  groupConfiguration={this.props.groupConfiguration}
                />
              ) : (
                <LaborAvailabilityDayWorker
                  availability={this.props.availability}
                  currentUser={this.props.currentUser}
                  i18n={this.props.i18n}
                  isRefused={this.isRefused}
                  isDesktopOrLarger={this.props.isDesktopOrLarger}
                  isRankVisible={!this.props.groupConfiguration.isOccasional || this.props.isManager}
                  workerRank={this.props.workerRank}
                  siteConfiguration={this.props.siteConfiguration}
                />
              )}
            </div>
            <div
              className={classNames('labor-availability-calendar__worker-availability-day__icon-column -right', {
                '-hidden': this.shouldShowManagerButtons,
              })}>
              {this.props.availability.workerCall && (
                <div className="labor-availability-calendar__worker-availability-day__corner-container -called">
                  <Icon id="call-end" className="-corner-icon -called" />
                </div>
              )}
              {this.props.availability.workerInfraction && (
                <div className="labor-availability-calendar__worker-availability-day__corner-container -infraction">
                  <Icon id="local-police" className="-corner-icon -infraction" />
                </div>
              )}
            </div>
          </button>
        )}
      </>
    );
  }
}
